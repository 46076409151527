import { useEffect, useState } from 'react';
import TableWrapper from '../../table-elements/TableWrapper';
import PageTitle from '../../page-title/PageTitle';
import { Button, Grid } from '@material-ui/core';
import axios from 'axios';


import "./../Settings.scss";
import Loader from '../../loader/Loader';
import RewardCard from './component/RewardCard';


const RewardManagement = () => {

  const [loader, setLoader] = useState(true)
  const [data, setData] = useState([])

  const IS_UAT = window.location.href.includes('-uat');
  const IS_SAT = window.location.href.includes('localhost') || window.location.href.includes('-sat');

  let BASEURL = `${IS_SAT ? "https://dappx-adminapi-sat.dappstore.me" : (IS_UAT ? "https://dappx-adminapi-uat.dappstore.me" : "https://dappx-adminapi.dappstore.me")}`;
  // BASEURL = 'http://localhost:5000'
  const fetch = () => {
    const adminToken = localStorage.getItem("token");
    axios.get(`${BASEURL}/reward-setting`,{ headers: {
      'auth-token': adminToken,
    }})
    .then((res) => {
      setLoader(false)
      setData(res.data.records)
     console.log(res.data.token_packages)
    })
    .catch((err) => {
      console.log('ERROR',err)
      setLoader(false)
    })
  }


  const init = () => {

  const adminToken = localStorage.getItem("token");

    setLoader(true);
    axios
      .post(`${BASEURL}/reward-setting/init`, {
        headers: {
          "auth-token": adminToken,
        },
      })
      .then((res) => {
        fetch();
        console.log("init",res.data);
      })
      .catch((err) => {
        console.log("ERROR", err);
        setLoader(false);
      });
  };


  useEffect(() => {
    fetch()
  },[])
  

  return (
    <>
      <PageTitle title="Reward Management" hideHistoryBtn hideAddBtn />
      <div className="content">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {loader && <TableWrapper body={<Loader appear width={50} />} />}

            {!loader && (
              <TableWrapper
                body={
                  <>
                    {data.map((i) => (
                      <RewardCard info={i} />
                    ))}

                    {!data.length > 0 && (
                      <TableWrapper
                        body={
                          <div style={{ textAlign: "center" }}>
                            <p>No reward is installed yet. Just initialize the reward management</p>
                            <Button
                              variant="contained"
                              disableElevation
                              color="primary"
                              className="edit-btn"
                              onClick={(event) => {
                                init();
                              }}
                            >
                              Initialize Reward
                            </Button>
                          </div>
                        }
                      />
                    )}
                  </>
                }
              />
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default RewardManagement
