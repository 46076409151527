import dayjs from 'dayjs'
var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)
export const formatDate = (dateString) => {
  const dateObj = new Date(dateString)

  const month = dateObj.toLocaleString('default', { month: 'short' })
  const date = dateObj.getDate()
  const year = dateObj.getFullYear()

  return `${month} ${date}, ${year}`
}

export const formatDateYearly = (dateString) => {
  const dateObj = new Date(dateString)

  const month = dateObj.toLocaleString('default', { month: 'short' })
  const date = dateObj.getDate()
  const year = dateObj.getFullYear()

  return dayjs.utc(dateObj).format('YY-MM-DD  HH:mm:ss') 
}