import { useState, useEffect } from 'react';
import { Button, Modal, FormControl, TextField, makeStyles, Fade, RadioGroup, Radio, FormControlLabel } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import currencyIcon from '../../../assets/images/currency/dappx.png';
import { DropzoneArea } from 'material-ui-dropzone';
import FadedContainer from '../../../components/faded-container/FadedContainer';
import Loader from '../../../components/loader/Loader';
import { ReactComponent as Add } from '../../../assets/icons/add.svg';
import '../Settings.scss';
import './DappxPaypal.scss';
import axios from 'axios';

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    top: '36%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '400px',
    minHeight: '400px',
    maxHeight: '700px',
    bgcolor: 'background.paper',
    border: '1px solid grey',
    borderRadius: '20px',
    boxShadow: 24,
    p: 4,
    backgroundColor: 'white'
  },
  textFieldContainer: {
    position: 'relative',
    display: 'inline-block'
  },
  textFieldImg: {
    position: 'absolute',
    right: 0,
    top: 17,
    width: 20,
    height: 20
  },
  TextFieldBorder: {
    [`& fieldset`]: {
      borderRadius: 20,
      width: 690,
      height: 40,
      paddingBottom: 7,
      marginTop: '7px',
      display: 'flex',
      alignItems: 'flex-start',
    },
    width: '690px',
  }
});

const SettingsModal = (props) => {
  const classes = useStyles()
  
  const [id, setId] = useState('')
  const [packageName, setPackageName] = useState('')
  const [tokenCount, setTokenCount] = useState('')
  const [markupVal, setMarkupVal] = useState(0)
  const [feesVal, setFeesVal] = useState(0)
  const [makrupPercent, setMarkupPercent] = useState('markup-amount')
  const [feesPercent, setFeesPercent] = useState('fees-amount')
  const [notesVal, setNotesVal] = useState('')
  const [imageVal, setImageVal] = useState([]);
  const [initial_image, set_initial_file] = useState([]);
  const [image_remove, set_image_remove] = useState(false);
  const [old_path, set_old_path] = useState();
  const [image_loaded, set_image_loaded] = useState(false);
  const [outline_error, set_outline_error] = useState([]);
  const [err_message, set_err_message] = useState('');

  const on_image_load = () => {
    setTimeout(() => {
      set_image_loaded(true);
    }, 200)
  }


  const IS_UAT = window.location.href.includes('-uat');
  const IS_SAT = window.location.href.includes('localhost') || window.location.href.includes('-sat');

  const BASEURL = `${IS_SAT ? "https://dappx-adminapi-sat.dappstore.me" : (IS_UAT ? "https://dappx-adminapi-uat.dappstore.me" : "https://dappx-adminapi.dappstore.me")}`;
  const adminToken = localStorage.getItem("token");

  useEffect(() => {
   if(props.openEditModal) {
    if(props.data) {
      setId(props.data?.id)
      setPackageName(props.data.package_name)
      setTokenCount(props.data.token_count)
      setMarkupVal(props.data.markup)
      setFeesVal(props.data.fees)
      setMarkupPercent(props.data.is_markup_percent === 'True' ? 'markup-percent' : 'markup-amount')
      setFeesPercent(props.data.is_fees_percent === 'True' ? 'fees-percent' : 'fees-amount')
      setNotesVal(props.data.notes)

      if(props.data.image?.length > 0){
        let image_path = props.data.image[0].path;
        let image_url = `${BASEURL}/${image_path}`;
        set_old_path(image_path)
        set_initial_file([image_url])
      }
  
    }
    if (props.data && props.data.image?.length > 0) {
      set_image_loaded(false)
    } else {
      set_image_loaded(true)
    }

  }

  }, [props.data])


  const feesValChange = (e) => {
    setFeesVal(e.target.value)
  }

  const packageNameChange = (e) => {
    setPackageName(e.target.value)
  }

  const notesValChange = (e) => {
    setNotesVal(e.target.value)
  }

  const tokenCountChange = (e) => {
    setTokenCount(e.target.value)
  }

  const markupValChange = (e) => {
    setMarkupVal(e.target.value)
  }

  const onChangeImage = (file) => {
    remove_errors('avatar')
    setImageVal(file)
  }

  const remove_errors = (target) => {
    set_err_message('')
    let update_outline_error = outline_error.filter(id => id !== target)
    set_outline_error(update_outline_error)
  }

  const handleMarkupChange = e => {
    setMarkupPercent(e.target.value)
  };

  const handleFeesChange = e => {
    setFeesPercent(e.target.value)
  };

  const submitModal = (e) => {
    e.preventDefault();
    const required_fields = packageName && tokenCount
    if(required_fields) { 
      if (props.modalTitle.includes('Add')) {
        if (!imageVal.length) {
          set_err_message("Image is required");
          return 
        }
        executeAdd()
      } else {
        executeEdit()
      }
    } else {
      set_err_message('Please fill required fields')
    }
   
  }

  const executeAdd = async() => {
    const isPercent = makrupPercent === 'markup-amount' ? false : true
    const isFeesPercent = feesPercent === 'fees-amount' ? false : true

    let formData = new FormData();
    formData.append('id', id)
    formData.append('token', 'DAPPX')
    formData.append('package_name', packageName)
    formData.append('token_count', tokenCount)
    formData.append('markup', markupVal)
    formData.append('is_markup_percent', isPercent)
    formData.append('is_fees_percent', isFeesPercent)
    formData.append('fees', feesVal)
    formData.append('notes', notesVal)
    if (imageVal.length > 0 && imageVal[0].path) {
      formData.append('file', imageVal[0])
    }

    await axios.post(`${BASEURL}/token-packages`, formData, { headers: {
      'auth-token': adminToken,
    }})
    .then((res) => {
      setPackageName('')
      setTokenCount('')
      setMarkupVal('')
      setFeesVal('')
      setMarkupPercent('markup-amount')
      setFeesPercent('fees-amount')
      setNotesVal('')
      setImageVal([])
      set_initial_file([])
      set_image_loaded(false)
      props.onSubmit()
    })
    .catch((err) => {
      if(err.response.data.message) {
        set_err_message(err.response.data.message)
      } else {
        set_err_message('Something wrong happened, Please try again')
      }
    })
  }

  const executeEdit = async() => {
    const isPercent = makrupPercent === 'markup-amount' ? false : true
    const isFeesPercent = feesPercent === 'fees-amount' ? false : true

    let formData = new FormData();
    let fileValue = imageVal.length > 0 && imageVal[0].path ? imageVal[0] : undefined;
    formData.append('id', id)
    formData.append('token', 'DAPPX')
    formData.append('package_name', packageName)
    formData.append('token_count', tokenCount)
    formData.append('markup', markupVal)
    formData.append('is_markup_percent', isPercent)
    formData.append('is_fees_percent', isFeesPercent)
    formData.append('fees', feesVal)
    formData.append('notes', notesVal)
    formData.append('oldImageRemove', image_remove)
    formData.append('oldPath', old_path)
    formData.append('file', fileValue)

    await axios.put(`${BASEURL}/token-packages/${id}`, formData, { headers: {
      'auth-token': adminToken,
    }})
    .then((res) => {
      setPackageName('')
      setTokenCount('')
      setMarkupVal('')
      setFeesVal('')
      setMarkupPercent('markup-amount')
      setFeesPercent('fees-amount')
      setNotesVal('')
      setImageVal([])
      set_initial_file([])
      set_image_loaded(false)
      props.onSubmit()
    })
    .catch((err) => {
      if(err.response.data.message) {
        set_err_message(err.response.data.message)
      } else {
        set_err_message('Something wrong happened, Please try again')
      }
    })
  }

  const closeModal = () => {
    setId('')
    setPackageName('')
    setTokenCount('')
    setFeesVal('')
    setMarkupVal('')
    setMarkupPercent('markup-amount')
    setFeesPercent('fees-amount')
    setNotesVal('')
    setImageVal([])
    set_image_loaded(false)
    set_image_remove(false)
    set_initial_file([])
    props.onClose()
  }

  return (
    <>
      <Modal
        open={props.openEditModal}
        style={{overflow: 'scroll'}}
      >
        <div className={classes.root}>
          <div className="paper-wrapper">
            <div className="paper">

              <div className="header">
                <h2 className="title">{props.modalTitle}</h2>
              </div>
              <form>
                <div className="textfield-item-container">
                <FormControl className="edit-form-control">
                    <label className="form-label" htmlFor="package">Product</label>
                    <div className={classes.textFieldContainer}>
                      <TextField variant="outlined" className={classes.TextFieldBorder} value={packageName} onChange={packageNameChange}/>
                    </div>
                  </FormControl>

                   <FormControl className="edit-form-control">
                   <label className="form-label" htmlFor="package">Value</label>
                   <div className={classes.textFieldContainer}>
                     <img src={currencyIcon} alt="" className={classes.textFieldImg} />
                     <TextField variant="outlined" className={classes.TextFieldBorder} value={tokenCount} onChange={tokenCountChange} />
                   </div>
                 </FormControl>

                  <FormControl className="edit-form-control">
                    <label className="form-label" htmlFor="package">Markup</label>
                    <div className={classes.textFieldContainer}>
                      <TextField variant="outlined" className={classes.TextFieldBorder} value={markupVal} onChange={markupValChange}/>
                    </div>
                  </FormControl>

                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue={makrupPercent}
                      name="radio-buttons-group"
                      style={{marginLeft: '50px',display:"flex",flexDirection:"row"}}
                    >
                      <FormControlLabel value="markup-amount" onChange={handleMarkupChange} control={<Radio />} label="Markup Amount ( $ )" />
                      <FormControlLabel value="markup-percent" onChange={handleMarkupChange} control={<Radio />} label="Markup Percentage ( % )" />
                    </RadioGroup>
                  </FormControl>

                  <FormControl className="edit-form-control">
                    <label className="form-label" htmlFor="package">Fees</label>
                    <div className={classes.textFieldContainer}>
                      <TextField variant="outlined" className={classes.TextFieldBorder} value={feesVal} onChange={feesValChange}/>
                    </div>
                  </FormControl>

                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue={feesPercent}
                      name="radio-buttons-group"
                      style={{marginLeft: '50px',display:"flex",flexDirection:"row"}}
                    >
                      <FormControlLabel value='fees-amount' onChange={handleFeesChange} control={<Radio />} label="Fees Amount ( $ )" />
                      <FormControlLabel value='fees-percent' onChange={handleFeesChange} control={<Radio />} label="Fees Percentage ( % )" />
                    </RadioGroup>
                  </FormControl>

                  <FormControl className="edit-form-control">
                    <label className="form-label" htmlFor="package">Description</label>
                    <div className={classes.textFieldContainer}>
                      <TextField variant="outlined" className={classes.TextFieldBorder} value={notesVal} onChange={notesValChange}/>
                    </div>
                  </FormControl>

                  <FormControl className="form-control upload-form" style={{width: '90%', margin: 'auto', display: 'flex', flexDirection: 'row'}}>
                    <label>Image</label>
                    <img src={initial_image} className="d-none" onLoad={on_image_load} alt="no-preview" />
                    <FadedContainer
                      // loading={!image_loaded}
                      duration={.2}
                      delay={.2}
                      loader={
                        <Loader appear={true} width="30" thick />
                      }>
                      <div className="dropzone-wrapper">
                        <DropzoneArea
                          // maxFileSize={104857600} // bytes in binary 100
                          // maxFileSize={103809024} // bytes in binary 99
                          maxFileSize={100000000}
                          initialFiles={initial_image}
                          dropzoneClass={`drop-image ${outline_error.includes("avatar") ? 'error' : ''}`}
                          previewGridClasses={{
                            container: "priview-container",
                            item: "priview-item",
                          }}
                          Icon={Add}
                          filesLimit={1}
                          acceptedFiles={['.jpg, .jpeg, .png, .bmp, .svg']}
                          dropzoneText={`Product Image`}
                          onChange={(file) => onChangeImage(file)}
                          getFileAddedMessage={() => `Image successfully attached.`}
                          getDropRejectMessage={(file_data, accepted_file, max_file) => {
                            console.log(file_data, accepted_file, max_file)
                            if(file_data.size > max_file){
                              return 'File was rejected. File is too big. Size limit is 100 megabytes.'
                            } else {
                              return 'File was rejected. File type not supported.'
                            }
                          }}
                          getFileRemovedMessage={() => {
                            set_image_remove(true)
                            return `Image removed.`
                          }}
                        />
                        <p className="additional-text">JPEG, SVG, PNG, or BMP only. Max 100mb</p>
                      </div>
                    </FadedContainer>
                  </FormControl>
                </div>
              </form>
              <div className="footer">
                <Button
                  disableElevation
                  color="primary"
                  variant="contained"
                  className="submit-btn"
                  type="submit"
                  onClick={submitModal}
                >
                  <span className="text">Save</span>
                </Button>
                <Button
                  disableElevation
                  variant="contained"
                  className="exit-btn"
                  onClick={closeModal}
                >
                  Exit
                </Button>

              </div>
                {err_message &&
                  <Fade className="error-message" in={err_message ? true : false} timeout={500} style={{display: 'flex', justifyContent: 'center', border: 'none'}}>
                    <Alert severity="error" variant="outlined">{err_message}</Alert>
                  </Fade>
                }
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default SettingsModal