
import { Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import axios from "axios";
import { useState } from "react";


const IS_UAT = window.location.href.includes('-uat');
const IS_SAT = window.location.href.includes('localhost') || window.location.href.includes('-sat');
let BASEURL = `${IS_SAT ? "https://dappx-adminapi-sat.dappstore.me" : (IS_UAT ? "https://dappx-adminapi-uat.dappstore.me" : "https://dappx-adminapi.dappstore.me")}`;


// BASEURL = 'http://localhost:5000'

const FeeCard = ({ info }) => {
  const adminToken = localStorage.getItem("token");

  const [fee, setFee] = useState(info);
  const [loading,setLoading] = useState(false)
  const [errorMessage,setErrorMessage] = useState(null)
  const [message,setMessage] = useState(null)

  const changeForm = (event) => {
    setFee({
      ...fee,
      [event.target.name]: event.target.value,
    });
  };

  const submitForm = () => {

    setLoading(true)
    setErrorMessage(null)
    setMessage(null)
    axios.patch(`${BASEURL}/fees/${fee._id}`,fee,{ headers: {
      'auth-token': adminToken,
    }})
    .then((res) => {
      setLoading(false)
      console.log('PAYPAL', res.data)
      setMessage(res.data.message)
    })
    .catch((err) => {
      console.log('ERROR', err.response.data.message)
      setErrorMessage(err.response.data.message)
      setLoading(false)
    })
    
  };
  return (
    <Grid xs={6}>
                      
      <form onSubmit={(e) => { e.preventDefault();  submitForm()}}>
        <input hidden name="id" value={fee._id}  />

        <fieldset style={{ padding: "30px", borderRadius: "16px", border: "1px solid #E0E0E0" }}>
          <legend>{info.title}</legend>

          <FormControl className="form-control" style={{ width: "100%" }}>
            <label className="form-label" htmlFor="package">Title</label>
            <div className="">
              <TextField variant="outlined" name="title" className="" 
                              onChange={changeForm} value={fee.title} style={{ width: "100%" }} />
            </div>
          </FormControl>
          <br />
          <br />


          <FormControl>
            <label className="form-label" htmlFor="package">Fee Type</label>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={50}
              name="radio-buttons-group"
              style={{ marginLeft: '50px', display: 'flex', flexDirection: "row" }}
            >
              <FormControlLabel control={<Radio  value="flat" name="amountType"  onChange={changeForm}  checked={fee.amountType=='flat'}  />} label="Amount ( $ )" />
              <FormControlLabel  control={<Radio  value="percentage"  name="amountType"  checked={fee.amountType=='percentage'} onChange={changeForm} />} label="Percentage ( % )" />
            </RadioGroup>
          </FormControl>
          <br />
          <FormControl className="form-control" style={{ width: "100%" }}>
            <label className="form-label" htmlFor="package">Fee Amount</label>
            <div className="">
              <TextField variant="outlined"  name="amount" className="" type={"number"} defaultValue={0} value={fee.amount}  onChange={changeForm} style={{ width: "100%" }} />
            </div>
          </FormControl>
          <br />
          <br />
          { errorMessage && 
            <Alert severity="error"  style={{marginBottom:"15px"}}>{errorMessage}</Alert>
          }
          { message && 
            (<Alert severity="success" style={{marginBottom:"15px"}}>{message}</Alert> )
          }
          <Button
            color="primary"
            variant="contained"
            className="text-right"
            type="submit"
          >{!loading ?"Update":"Updating..."}</Button>
        </fieldset>
      </form>

      <br />
      <br />

    </Grid>
  )
}
export default FeeCard