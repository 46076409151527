import React, {useState, useEffect} from 'react'
import Web3 from 'web3'
import TableWrapper from '../../table-elements/TableWrapper'
import PageTitle from '../../page-title/PageTitle';
import { Grid, Paper, Tooltip, Typography, Switch } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import { ReactComponent as MultipleUsers } from '../../../assets/icons/multiple-users.svg';
import axios from 'axios'   
import AceWithdrawal from './AceWithdrawal'
import AceWithdrawSettings from './AceWithdrawSettings';
import AddressesTable from '../buy-ace/AddressesTable';
import HotWalletHistory from '../buy-ace/HotWalletHistory';
import { ConditionalModal } from '../../alerts/Alerts';
import SuccessModal from '../../alerts/Success'
import ErrorModal from '../../alerts/Error'
import WithdrawLimitModal from './WithdrawLimitModal';
import '../ace-governance/Widget.scss'
import './switch.scss'

const AceWithdrawMain = () => {
  let web3 = new Web3(window.ethereum);
  const [title, setTitle] = useState('')
  const [deleteModal, setDeleteModal] = useState(false)
  const [id, setId] = useState('')
  const [totalCore, setTotalCore] = useState(0)
  const [totalNitro, setTotalNitro] = useState(0)
  const [totalAce, setTotalAce] = useState(0)
  const [totalUbi, setTotalUbi] = useState(0)
  const [withdrawalStatus, setWithdrawalStatus] = useState(false)
  const [hotWalletModal, setHotWalletModal] = useState(false)
  const [walletData, setWalletData] = useState({});
  const [deleteWalletAddress, setDeleteWalletAddress] = useState('')
  const [success, setSuccess] = useState(false)
  const [deployedContract, setDeployedContract] = useState()
  const [txError, setTxError] = useState(false)
  const [loadingBtn, setLoadingBtn] = useState(false)
  const [refresh, setRefresh] = useState(0)
  const [limitModal, setLimitModal] = useState(false)


  const IS_UAT = window.location.href.includes('-uat');
  const IS_SAT = window.location.href.includes('localhost') || window.location.href.includes('-sat');
  const token = localStorage.getItem('token')
  const DAPPX_ADMIN_API = `${IS_SAT ? "https://dappx-adminapi-sat.dappstore.me/" : (IS_UAT ? "https://dappx-adminapi-uat.dappstore.me/" : "https://dappx-adminapi.dappstore.me/")}`;
  const ACE_CONTRACT_API = `${IS_SAT ? "https://dappx-api-sat.dappstore.me/" : (IS_UAT ? "https://dappx-api-uat.dappstore.me/" : "https://dappx-api.dappstore.me/")}`;


  useEffect(() => {
    axios.get(`${DAPPX_ADMIN_API}ace-withdrawal/total-fees/native`, {headers: { 'auth-token': token}})
    .then((res) => {
      setTotalCore(res.data.withdrawal_fees.core)
      setTotalNitro(res.data.withdrawal_fees.nitro)
      setTotalUbi(res.data.withdrawal_fees.ubi)
    })
    .catch((err) => {
      console.log(err)
    })
  },[])

  useEffect(() => {
    axios.get(`${DAPPX_ADMIN_API}ace-withdrawal/total-withdrawn/native`, {headers: { 'auth-token': token}})
    .then((res) => {
      setTotalAce(res.data.total_ace)
    })
    .catch((err) => {
      console.log(err)
    })
  },[])

  useEffect(() => {
    axios.get(`${DAPPX_ADMIN_API}ace-withdrawal/settings/withdrawal-status/native`, {headers: { 'auth-token': token}})
    .then((res) => {
     setWithdrawalStatus(res.data.allow_withdrawal)
    })
    .catch((err) => {
      console.log(err)
    })
  },[withdrawalStatus])


  const switchWithdrawalStatus = () => {
    let data ={
      allow_withdrawal: !withdrawalStatus,
      token_type: 'native'
    }

    axios.post(`${DAPPX_ADMIN_API}ace-withdrawal/settings/withdrawal-status`, data, { headers:{ 'auth-token': token}}
    ).then((res) => {
      if (res.data.message == 'Ok') {
        setWithdrawalStatus(!withdrawalStatus)
      }
    }).catch((error) => {
      console.log(error)
      setWithdrawalStatus(withdrawalStatus)
    })
  }
  
  const openDeleteModal = (row) => {
    setDeleteModal(true)
    setDeleteWalletAddress(row.wallet)
  }

  const deleteWallet = async() => {
    setLoadingBtn(true)
    const data = {
      wallet: deleteWalletAddress.toLocaleLowerCase()
    }
    await axios.post(`${DAPPX_ADMIN_API}ace-withdrawal/delete-native-hot-wallet`, data, {headers: { 'auth-token': token}})
    .then((res) => {
      setSuccess(true)
      setLoadingBtn(false)
    })
    .catch((err) => {
      setLoadingBtn(false)
    })

  }

  const confirmSuccess = () => {
    setSuccess(false)
    setDeleteModal(false)
    setRefresh(prev => prev + 1)
  }

  const confirmError = () => {
    setTxError(false)
    setDeleteModal(false)
  }

  const openLimitModal = () => {
    setLimitModal(true)
  }

  const closeLimitModal = () => {
    console.log(limitModal)
    setLimitModal(false)
  }

  const BlueOnGreenTooltip = withStyles({
    tooltip: {
      color: "#111111",
      backgroundColor: "#ffffff",
      fontSize: '13px',
      width: '500px',
      border: '10.5px solid #898989',
    }
  })(Tooltip);

  const tooltipData = <p>Accumulated amount of the successful ACE Withdrawal from the dAppstore ecosytem.</p>

  return (
    <>
      <PageTitle title="ACE ARC20 Withdrawal" hideHistoryBtn hideAddBtn />
      <div className="content">
        <div className='widget-container'>
        <Grid container spacing={1}>
					<Grid item lg={11} md={6} sm={12}>
						<Paper elevation={0} className="card-paper">

							<div className="title">
								<span className="icon"><MultipleUsers /></span>
                Total Collected Nitro	
							</div>
							<div className="widget-numbers">
                {totalNitro.toFixed(6).replace(/\.?0+$/, '')}
							</div>

						</Paper>
					</Grid>
				</Grid>

        <Grid container spacing={1}>
					<Grid item lg={11} md={6} sm={12}>
						<Paper elevation={0} className="card-paper">

							<div className="title">
								<span className="icon"><MultipleUsers /></span>
								Total Collected UBI Protocol
							</div>
							<div className="widget-numbers">
                {totalUbi.toFixed(6).replace(/\.?0+$/, '')}
							</div>

						</Paper>
					</Grid>
				</Grid>

        <Grid container spacing={1}>
					<Grid item lg={11} md={6} sm={12}>
						<Paper elevation={0} className="card-paper">

							<div className="title">
								<span className="icon"><MultipleUsers /></span>
								Total Collected Core Ecosystem Dev
							</div>
							<div className="widget-numbers">
                {totalCore.toFixed(6).replace(/\.?0+$/, '')}
							</div>

						</Paper>
					</Grid>
				</Grid>

        <Grid container spacing={1}>
					<Grid item lg={11} md={6} sm={12}>
						<Paper elevation={0} className="card-paper">

							<div className="title">
								<span className="icon"><MultipleUsers /></span>
								<BlueOnGreenTooltip title={tooltipData}>
                  <p
                   className='withdraw-widget'
                   >Total ACE Withdrawn</p>
                </BlueOnGreenTooltip>
							</div>
							<div className="widget-numbers">
                {totalAce.toFixed(6).replace(/\.?0+$/, '')}
							</div>

						</Paper>
					</Grid>
				</Grid>
        </div>
        <div className="switch-container">
        <Typography
          variant="subtitle2"
          gutterBottom
          component="div"
          style={{margin: '10px', fontWeight: 600}}
        >
          Withdrawal Status
          </Typography>
          <Switch
            // checked={maintenanceOn}
            checked={withdrawalStatus}
            color="primary"
            onChange={switchWithdrawalStatus}
          />
        </div>

        <Grid container spacing={3}>
					<Grid item xs={12}>
						<TableWrapper
            className={'wallet-address-list'}
          body={
            <>
            <AddressesTable
              nativeAce
              onDelete={openDeleteModal}
              data={walletData}
              showWalletHistory={() => setHotWalletModal(true)}
              refresh={refresh}
              onApprove={openLimitModal}
             />
             <WithdrawLimitModal
             openEditModal={limitModal}
             close={closeLimitModal}
             />
          </>
          } 
						/>
					</Grid>
				</Grid>

        <Grid container spacing={3}>
					<Grid item xs={12}>
						<TableWrapper
            className={'wallet-address-list'}
          body={
            <AceWithdrawSettings />
          }
						/>
					</Grid>
				</Grid>
      
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<TableWrapper
          body={
            <AceWithdrawal />
          }
						/>
					</Grid>
				</Grid>
			</div>
      <HotWalletHistory
        nativeAce
        modal={hotWalletModal}
        onClose={() => setHotWalletModal(false)}
      />
       <ConditionalModal
        title={title}
        description="Are you sure you want to delete this Hot Wallet?"
        open={deleteModal}
        onAccept={deleteWallet}
        onDenied={() => setDeleteModal(false)}
        acceptBtn_label="Delete"
        loadingBtn={loadingBtn}
      />
       <SuccessModal 
        open={success}
        onClose={confirmSuccess}
        />
        <ErrorModal 
        open={txError}
        onClose={confirmError}
        />
    </>
  )
}

export default AceWithdrawMain
