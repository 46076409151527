import React, { useState } from 'react';
import { Button, Modal, FormControl, TextField, makeStyles, Fade } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Loader from '../../../components/loader/Loader';

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    top: '36%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '400px',
    // minHeight: '400px',
    // maxHeight: '700px',
    bgcolor: 'background.paper',
    border: '1px solid grey',
    borderRadius: '20px',
    boxShadow: 24,
    p: 4,
    backgroundColor: 'white'
  },
  textFieldContainer: {
    position: 'relative',
    display: 'inline-block'
  },
  TextFieldBorder: {
    [`& fieldset`]: {
      borderRadius: 20,
      width: 690,
      height: 40,
      paddingBottom: 7,
      marginTop: '7px',
      display: 'flex',
      alignItems: 'flex-start',
    },
    width: '690px',
  }
});

const WithdrawLimitModal = (props) => {
  const classes = useStyles()
  const [value, setValue] = useState('')
  const [err_message, set_err_message] = useState('');

  const handleChange = (e) => {
    setValue(e.target.value)
  }

  const onsubmit = () => {
    props.approveWallet(value)
    setValue('')
  }

  const closeModal = () => {
      setValue('')
      props.close()
  }

  return (
    <>
      <Modal
        open={props.openEditModal}
      >
        <div className={classes.root}>
          <div className="paper-wrapper">
            <div style={{width: 800}}>

              <div className="header">
                <h2 className="title">{props.modalTitle}</h2>
              </div>
              <form>
                <div className="textfield-item-container">
                <FormControl className="edit-form-control">
                    <label className="form-label" htmlFor="package">Wallet Max Withdrawal Amount</label>
                    <div className={classes.textFieldContainer}>
                      <TextField variant="outlined" className={classes.TextFieldBorder} value={value} onChange={handleChange} placeholder='Enter amount'/>
                    </div>
                  </FormControl>
                </div>
              </form>
              <div className="footer">
                <Button
                  disableElevation
                  color="primary"
                  variant="contained"
                  className="submit-btn"
                  type="submit"
                  onClick={onsubmit}
                >
                  <span className="text">Save</span>
                </Button>
                <Button
                  disableElevation
                  variant="contained"
                  className="exit-btn"
                  onClick={closeModal}
                >
                  Exit
                </Button>

              </div>
                {err_message &&
                  <Fade className="error-message" in={err_message ? true : false} timeout={500} style={{display: 'flex', justifyContent: 'center', border: 'none'}}>
                    <Alert severity="error" variant="outlined">{err_message}</Alert>
                  </Fade>
                }
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default WithdrawLimitModal