import React, {useState, useEffect} from 'react'
import { Button,Typography, TextField, makeStyles, Tooltip, Grid } from '@material-ui/core';
import currencyIcon from '../../../assets/images/currency/acent-logo.svg'
import { withStyles } from "@material-ui/core/styles";
import Loader from '../../loader/Loader';
import { DAPPX_API_BASE_URL } from '../../../constant/config'
import moment from 'moment';
import axios from 'axios'

const useStyles = makeStyles({
  root: {
      display: "flex",
      alignItems: "center",
      "& .myIcon": {
        paddingLeft: "8px",
        order: 999
    }
  },
  textFieldContainer: {
    position: 'relative',
    display: 'inline-block'
  },
  textFieldImg: {
    position: 'absolute',
    right: 10,
    top: 17,
    width: 20,
    height: 20
  },
  primaryGrid: {
    margin: '8px'
  },
  TextFieldBorder: {
    [`& fieldset`]: {
      borderRadius: 20,
  },
  width: '300px',
  },
  midSection: {
    width: '100%',
    display: 'flex',
    justifyContent:'space-between',
  },
  aTag: {
    textDecoration: 'none',
    color: 'blue',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center'
  },
  buttonsHolder : {
    marginTop: '50px',
    display: 'flex',
    justifyContent: 'center'
  },
  nestedTextFieldsContainer:{
    marginLeft: '155px',
    marginTop: '10px'
  },
  pStyle: {
    marginTop: '10px',
    fontWeight: 500
  }
});

const AceWithdrawSettings = ({data}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [dailyLimit, setDailyLimit] = useState(0)
  const [initDailyLimit, setInitDailyLimit] = useState(0)
  const [userDailyLimit, setUserDailyLimit] = useState(0)
  const [initUserDailyLimit, setInitUserDailyLimit] = useState(0)
  const [withdrawFeeCore, setWithdrawFeeCore] = useState(0)
  const [initWithdrawFeeCore, setInitWithdrawFeeCore] = useState(0)
  const [withdrawFeeNitro, setWithdrawFeeNitro] = useState(0)
  const [initWithdrawFeeNitro, setInitWithdrawFeeNitro] = useState(0)
  const [withdrawFeeUbi, setWithdrawFeeUbi] = useState(0)
  const [initWithdrawFeeUbi, setInitWithdrawFeeUbi] = useState(0)
  const [sumWithdrawal, setSumWithdrawal] = useState(0)
  const [showButtons, setShowButtons] = useState(false)

  const CutomToolTip = withStyles({
    tooltip: {
      color: "#111111",
      backgroundColor: "#ffffff",
      fontSize: '13px',
      border: '0.5px solid #898989',
      textAlign: 'center'
    }
  })(Tooltip);

  const IS_UAT = window.location.href.includes('-uat');
  const IS_SAT = window.location.href.includes('localhost') || window.location.href.includes('-sat');
  const token = localStorage.getItem('token')
  const DAPPX_ADMIN_API = `${IS_SAT ? "https://dappx-adminapi-sat.dappstore.me/" : (IS_UAT ? "https://dappx-adminapi-uat.dappstore.me/" : "https://dappx-adminapi.dappstore.me/")}`

  useEffect(() => {
    setLoading(true)
  }, [])

  useEffect(() => {
    axios.get(`${DAPPX_API_BASE_URL}/ace-withdrawal/settings/token`, {headers: { 'auth-token': token}})
    .then((res) => {
      setDailyLimit(res.data.daily_limit)
      setInitDailyLimit(res.data.daily_limit)
      setUserDailyLimit(res.data.daily_limit_per_user)
      setInitUserDailyLimit(res.data.daily_limit_per_user)
      setWithdrawFeeCore(res.data.withdrawal_fee_core)
      setInitWithdrawFeeCore(res.data.withdrawal_fee_core)
      setWithdrawFeeNitro(res.data.withdrawal_fee_nitro)
      setInitWithdrawFeeNitro(res.data.withdrawal_fee_nitro)
      setWithdrawFeeUbi(res.data.withdrawal_fee_ubi)
      setInitWithdrawFeeUbi(res.data.withdrawal_fee_ubi)
      setSumWithdrawal( res.data.withdrawal_fee_core + res.data.withdrawal_fee_nitro + res.data.withdrawal_fee_ubi)
      setLoading(false)
         })
         .catch((err) => {
           console.log(err)
         })
       },[])

  useEffect(() => {
    setSumWithdrawal( parseInt(withdrawFeeCore) + parseInt(withdrawFeeNitro) + parseInt(withdrawFeeUbi))
  },[sumWithdrawal])

  const dailyLimitChange = (e) => {
      setDailyLimit(e.target.value)
      setShowButtons(true)
    }
    
    const userDailyLimitChange = (e) => {
      setUserDailyLimit(e.target.value) 
      setShowButtons(true)
    }
    
    const withdrawFeeNitroChange = (e) => {
      setWithdrawFeeNitro(e.target.value) 
      setShowButtons(true)
    }

    const withdrawFeeCoreChange = (e) => {
        setWithdrawFeeCore(e.target.value) 
        setShowButtons(true)
      }
      
     const withdrawFeeUbiChange = (e) => {
      setWithdrawFeeUbi(e.target.value) 
      setShowButtons(true)
      //  let target = e.target.value
      //  if(isNaN(e.target.value)) {
      //      console.log('Hero',  target)
      //     setWithdrawFeeUbi('') 
      //     setShowButtons(true)
      //   } else {
      //     setWithdrawFeeUbi(parseInt(e.target.value)) 
      //     setShowButtons(true)
      //   }
      }
      
     const cancelChanged =() =>{
        setShowButtons(false)
        setDailyLimit(initDailyLimit) 
        setUserDailyLimit(initUserDailyLimit) 
        setWithdrawFeeCore(initWithdrawFeeCore) 
        setWithdrawFeeNitro(initWithdrawFeeNitro) 
        setWithdrawFeeUbi(initWithdrawFeeUbi) 
      }

      const updateWithdrawConfig = async(e) => {
         setLoading(true)
         e.preventDefault();
         setShowButtons(false)
         let data ={
           daily_limit: dailyLimit, 
           daily_limit_per_user: userDailyLimit,
           withdrawal_fee_core: withdrawFeeCore, 
           withdrawal_fee_nitro: withdrawFeeNitro, 
           withdrawal_fee_ubi: withdrawFeeUbi,
         }
         await axios.post(`${DAPPX_API_BASE_URL}/ace-withdrawal/settings/token`, data, {headers: { 'auth-token': token}})
          .then((res) => {
            setLoading(false)
            setSumWithdrawal( withdrawFeeCore + withdrawFeeNitro + withdrawFeeUbi)
          })
          .catch((err) => {
            setLoading(false)
            console.log(err)
          })
        }

  return (
    <>
    <div className="content">
      {loading ?
      <Loader />
      :
      <form>
         <div className={classes.midSection}>
        <p><b>Withdrawal Policy</b></p>
      </div>
      <Grid container spacing={3}>
         {/* Inputs Section */}
               <Grid container spacing={12} className={classes.primaryGrid}>
                 <Grid item xs={3}>
                   <Typography>Daily Limit</Typography>
                 </Grid>
                 <div className={classes.textFieldContainer}>
                   <img src={currencyIcon} alt="" className={classes.textFieldImg}/>
                   <TextField
                     variant="outlined"
                     className={classes.TextFieldBorder}
                     value={dailyLimit}
                     placeholder="0.0"
                     onChange={(e) => dailyLimitChange(e)}
                   />
                 </div>
               </Grid>
       </Grid>
  
       <Grid container spacing={3}>
         {/* Inputs Section */}
            <Grid container spacing={12} className={classes.primaryGrid}>
              <Grid item xs={3}>
                <Typography>Daily Limit per User</Typography>
              </Grid>
              <div className={classes.textFieldContainer}>
                <img src={currencyIcon} alt="" className={classes.textFieldImg}/>
                <TextField
                  variant="outlined"
                  className={classes.TextFieldBorder}
                  value={userDailyLimit}
                  onChange={(e) => userDailyLimitChange(e)}
                />
              </div>
            </Grid>
       </Grid>
  
       <Grid container spacing={3} style={{marginBottom: '40px'}}>
               <Grid container spacing={12} className={classes.primaryGrid}>
                 <Grid item xs={3}>
                   <Typography>Withdrawal Fee <b>{sumWithdrawal} ACE</b></Typography>
                 </Grid>
               </Grid>
  
               <Grid container spacing={3} className={classes.nestedTextFieldsContainer}>
                 <Grid container spacing={12} className={classes.primaryGrid}>
                   <Grid item xs={3} lg={2}>
                     <Typography className={classes.pStyle}>Nitro</Typography>
                   </Grid>
                   <div className={classes.textFieldContainer} style={{marginLeft: '30px'}}>
                     <TextField
                       variant="outlined"
                       className={classes.TextFieldBorder}
                       value={withdrawFeeNitro}
                       onChange={(e) => withdrawFeeNitroChange(e)}
                     />
                   </div>
                 </Grid>
               </Grid>
  
               <Grid container spacing={3} className={classes.nestedTextFieldsContainer}>
                 <Grid container spacing={12} className={classes.primaryGrid}>
                   <Grid item xs={3} lg={2}>
                     <Typography className={classes.pStyle}>UBI Protocol</Typography>
                   </Grid>
                   <div className={classes.textFieldContainer} style={{marginLeft: '30px'}}>
                     <TextField
                       variant="outlined"
                       className={classes.TextFieldBorder}
                       value={withdrawFeeUbi}
                       placeholder='0'
                       onChange={(e) => withdrawFeeUbiChange(e)}
                     />
                   </div>
                 </Grid>
               </Grid>
  
               <Grid container spacing={3} className={classes.nestedTextFieldsContainer}>
                 <Grid container spacing={12} className={classes.primaryGrid}>
                   <Grid item xs={3} lg={2}>
                     <Typography className={classes.pStyle}>Core Ecosystem Dev</Typography>
                   </Grid>
                   <div className={classes.textFieldContainer} style={{marginLeft: '30px'}}>
                     <TextField
                       variant="outlined"
                       className={classes.TextFieldBorder}
                       value={withdrawFeeCore}
                       onChange={(e) => withdrawFeeCoreChange(e)}
                     />
                   </div>
                 </Grid>
               </Grid>
          </Grid>
        </form>
        }
      {showButtons ? 
      <div className="footer">
        <Button
          disableElevation
          color="primary"
          variant="contained"
          className="submit-btn"
          type="submit"
          onClick={updateWithdrawConfig}
          >
          <span className="text">Save</span>
        </Button>
        <Button
          disableElevation
          variant="contained"
          className="exit-btn"
          onClick={cancelChanged}
          >
          Cancel
        </Button>
      </div>
        : ''}
    </div>
    </>
  )
}

export default AceWithdrawSettings